<template>
  <div view="lHh Lpr lFf">
    <c-toast ref="toast" :auto="true" />
    <theme-provider :theme="vertical">
      <div
        class="drawer c-bg-white shadow-right"
        :style="{ width: !colapsedMenu ? '300px' : '56px' }"
      >
        <div class="ui-flex ui-py-10 ui-px-6 shadow-bottom">
          <div v-show="!colapsedMenu" class="c-text-h3 c-text-gray-400">
            Cast
          </div>
          <button
            class="ui-ml-auto ui-py-1"
            @click="colapsedMenu = !colapsedMenu"
          >
            <icon
              class="c-icon--right"
              :name="!colapsedMenu ? 'arrowLeft' : 'arrowRight'"
            ></icon>
          </button>
        </div>
        <ul>
          <router-link
            v-for="item in sideMenu"
            :key="item.name"
            :to="{ name: item.path }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li
              :class="[
                isActive && 'c-bg-gray-100',
                isExactActive && 'c-bg-gray-100',
              ]"
              class="ui-py-4 ui-px-6"
            >
              <a :href="href" @click="navigate"
                ><div
                  :class="[isActive ? 'c-text-gray-400' : 'c-text-gray-300']"
                  class="c-text-btn c-text-gray-300 ui-text-left"
                  :data-cy="item.qaAttribute"
                >
                  {{ colapsedMenu ? item.colapsedName : item.name }}
                </div>
              </a>
            </li></router-link
          >
        </ul>
      </div>
      <div  class="ui-duration-500" :style="{'margin-left': (colapsedMenu?56:300) +'px'}">
        <router-view @toast="showToast" />
      </div>
    </theme-provider>
  </div>
</template>

<script>
import { events, VERTICAL } from "../constants";
import { ThemeProvider, Icon } from "@estrategiahq/coruja-web-ui";
export default {
  components: {
    ThemeProvider,
    Icon,
  },
  created() {
    this.$subscribe(events.LOADING_START, () => {
      this.$q.loading.show();
    });
    this.$subscribe([events.LOADING_STOP, events.REQUEST_ERROR], () => {
      this.$q.loading.hide();
    });
    this.$subscribe(events.DIALOG_ERROR, (message) => {
      this.$q.dialog({
        title: "Alguma coisa errada não está certa",
        position: "bottom",
        message,
      });
    });
    this.$subscribe(events.SHOW_TOAST, (message) => {
      this.showToast(message);
    });
  },
  mounted() {
    this.vertical = sessionStorage.getItem(VERTICAL) || "concursos";
  },
  data() {
    return {
      leftDrawerOpen: true,
      mini: false,
      vertical: "",
      sideMenu: [
        { name: "Faixas", colapsedName: "F", path: "tracks", qaAttribute: "menu-faixas" },
        { name: "Álbuns", colapsedName: "A", path: "albums", qaAttribute: "menu-albuns" },
        { name: "Estantes", colapsedName: "E", path: "shelves", qaAttribute: "menu-estantes" },
        { name: "Editor da Home", colapsedName: "H", path: "home", qaAttribute: "menu-editor-da-home" },
      ],
      colapsedMenu: false,
    };
  },
  methods: {
    showToast(info) {
      this.$refs.toast._show({
        type: info.type || "success",
        text: info.text || "Ok",
        timeout: info.timeout || 0,
        width: info.width || 400,
        position: info.position || "top",
        handler: () => {
          alert("Acionado o botão de fechar toast");
          this.$refs.toast._close();
        },
      });
    },
  },
};
</script>

<style>
.shadow-bottom {
  box-shadow: 0px 1px 0px rgba(34, 34, 34, 0.08);
}
.shadow-right {
  box-shadow: 1px 0px 0px rgba(34, 34, 34, 0.08);
}
.drawer-background > .q-item-type {
  color: #fff;
  opacity: 0.6;
}
.drawer-menu-active {
  background: var(--c-color-gray-100);
  opacity: 1;
}
.drawer {
  height: 100%; /* 100% Full-height */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

</style>
