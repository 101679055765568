var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"view":"lHh Lpr lFf"}},[_c('c-toast',{ref:"toast",attrs:{"auto":true}}),_c('theme-provider',{attrs:{"theme":_vm.vertical}},[_c('div',{staticClass:"drawer c-bg-white shadow-right",style:({ width: !_vm.colapsedMenu ? '300px' : '56px' })},[_c('div',{staticClass:"ui-flex ui-py-10 ui-px-6 shadow-bottom"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.colapsedMenu),expression:"!colapsedMenu"}],staticClass:"c-text-h3 c-text-gray-400"},[_vm._v(" Cast ")]),_c('button',{staticClass:"ui-ml-auto ui-py-1",on:{"click":function($event){_vm.colapsedMenu = !_vm.colapsedMenu}}},[_c('icon',{staticClass:"c-icon--right",attrs:{"name":!_vm.colapsedMenu ? 'arrowLeft' : 'arrowRight'}})],1)]),_c('ul',_vm._l((_vm.sideMenu),function(item){return _c('router-link',{key:item.name,attrs:{"to":{ name: item.path },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"ui-py-4 ui-px-6",class:[
              isActive && 'c-bg-gray-100',
              isExactActive && 'c-bg-gray-100' ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"c-text-btn c-text-gray-300 ui-text-left",class:[isActive ? 'c-text-gray-400' : 'c-text-gray-300'],attrs:{"data-cy":item.qaAttribute}},[_vm._v(" "+_vm._s(_vm.colapsedMenu ? item.colapsedName : item.name)+" ")])])])]}}],null,true)})}),1)]),_c('div',{staticClass:"ui-duration-500",style:({'margin-left': (_vm.colapsedMenu?56:300) +'px'})},[_c('router-view',{on:{"toast":_vm.showToast}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }